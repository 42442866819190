export default {
  "pcj":"سعر الإغلاق",
  "app-title": "FOREX IFC",
  "app-title-2": "غير قابل للبيع",
  "app-title-language": "اللغة",
  "title-detail": "تفاصيل",
  "title-welcome_to_use": "مرحبا بك في الاستخدام",

  //tabBar
  "tabbar-market": "السوق",
  "tabbar-order": "الطلبات",
  "tabbar-news": "الأخبار",
  "tabbar-mine": "حسابي",
  "public-night_mode": "وضع الليل",
  "public-noRecord": "لا توجد بيانات",
  "public-text-cancel": "إلغاء",
  "public-text-confirm": "تأكيد",
  "public-text-balance": "الرصيد",
  "public-text-date": "الوقت",
  "public-text-completed": "مكتمل",
  "public-text-invalid": "غير صالح",
  "public-text-unchecked": "غير مفحوص",
  "public-text-remarks": "ملاحظات",
  "public-text-loading": "جارٍ التحميل",
  "public-text-loose-to-refresh": "اسحب لتحديث...",
  "public-text-buy": "شراء",
  "public-text-sell": "بيع",
  "public-text-close": "إغلاق السوق",
  "public-text-lots_1": "عقد",
  "public-text-lots": "عدد العقود",
  "public-text-available": "متاح",
  "public-text-freeze": "مجمد",
  "public-text-scroll_down_refresh": "اسحب لأسفل للتحديث...",
  "public-btn-submit": "تقديم",
  "public-btn-add": "إضافة",
  "public-btn-copy": "نسخ",
  "public-btn-delete": "حذف",
  "public-btn-copyLink": "نسخ رابط الدعوة",
  "public-btn-send": "إرسال",
  "public-btn-download": "تحميل",
  "public-btn-resetPassword": "إعادة تعيين كلمة المرور",
  "public-btn-confirmation": "تأكيد",
  "public-btn-accept": "قبول",
  "public-enterEmail": "أدخل بريدك الإلكتروني",
  "public-password": "كلمة المرور",
  "public-retypePassword": "إعادة إدخال كلمة المرور",
  "public-inviteCode": "رمز الدعوة",
  "public-verifyCode": "رمز التحقق",
  "login-newUser": "مستخدم جديد؟",
  "login-joinNow": "انضم الآن",
  "login-forgotPassword": "نسيت كلمة المرور",
  "login-btn-login": "تسجيل الدخول",
  "login-btn-demoAcc":
    "ابدأ التداول الآن باستخدام حساب تجريبي دون الحاجة لفتح حساب!",
  "login-enterEmail": "يرجى إدخال بريدك الإلكتروني",
  "login-enterPassword": "يرجى إدخال كلمة المرور",
  "regsiter-btn-register": "تسجيل",
  "regsiter-enterInviteCode": "يرجى إدخال رمز الدعوة",
  "regsiter-enterVerityCode": "يرجى إدخال رمز التحقق",
  "logout-title": "تسجيل الخروج",
  "systemHint-reset_successful": "إعادة تعيين ناجحة",
  "systemHint-incorrectVerityCode": "رمز التحقق غير صحيح",
  "systemHint-passwordsDoNotMatch": "كلمات المرور غير متطابقة",
  "systemHint-password_error": "خطأ في كلمة المرور",
  "systemHint-login_connot_find_user": "لم يتم العثور على المستخدم",
  "systemHint-copySuccess": "تم النسخ بنجاح",
  "systemHint-plsCompleted": "يرجى ملء جميع الحقول",
  "systemHint-pwdInconsistent": "كلمة المرور غير متطابقة",
  "systemHint-incorrectVerifyCode": "رمز التحقق غير صحيح",
  "systemHint-changeSuccess": "تم التغيير بنجاح",
  "systemHint-plsSubmit": "يرجى تقديم جميع المعلومات",
  "systemHint-binb-bank-not-open-yet": "لم يتم فتح السحب لهذه العملة بعد",
  "systemHint-plsFillIn": "يرجى ملء جميع الحقول",
  "systemHint-requestSuccessful": "الطلب ناجح",
  "systemHint-operation_successful": "العملية ناجحة",
  "systemHint-set_successful": "تم الإعداد بنجاح",
  "systemHint-missing_params-or-val-error": "معلمات مفقودة أو خطأ في القيمة",
  "systemHint-limit_trade_price_must_greater":
    "يجب أن يكون سعر التداول المحدد أكبر من 0",
  "systemHint-buy-stop_loss_price_higher_than_opening_price":
    "يجب ألا يكون سعر وقف الخسارة للشراء (طويل) أعلى من سعر الافتتاح",
  "systemHint-buy-stop_profit_price_higher_than_opening_price":
    "يجب ألا يكون سعر جني الأرباح للشراء (طويل) أعلى من سعر الافتتاح",
  "systemHint-sell-stop_loss_price_lower_than_opening_price":
    "يجب ألا يكون سعر وقف الخسارة للبيع (قصير) أقل من سعر الافتتاح",
  "systemHint-sell-stop_profit_price_lower_than_opening_price":
    "يجب ألا يكون سعر جني الأرباح للبيع (قصير) أقل من سعر الافتتاح",
  "systemHint-code_send_succesful": "تم إرسال رمز التحقق بنجاح",
  "systemHint-pls_register_login_real_account":
    "يرجى التسجيل أو تسجيل الدخول إلى حساب حقيقي",
  "systemHint-successful_exit": "تم تسجيل الخروج بنجاح",
  "popupHint-set_up_collection_account": "يرجى إعداد حساب الاستلام أولاً",
  "popupHint-confirm_to_delete": "هل أنت متأكد من أنك تريد الحذف؟",
  "popupHint-whether_return_it": "هل تريد إعادته؟",
  "mine-availableAssets": "الأصول المتاحة",
  "mine-account_details": "تفاصيل الحساب",
  "mine-deposit": "إيداع",
  "mine-withdraw": "سحب",
  "mine-billDetails": "تفاصيل الفاتورة",
  "mine-demo_return_simulated_account": "إعادة تعيين الحساب التجريبي",
  "mine-wallet": "محفظة",
  "mine-verifyName": "التحقق من الهوية",
  "mine-inviteFriend": "دعوة الأصدقاء",
  "mine-changePassword": "تغيير كلمة المرور",
  "mine-onlineSerive": "خدمة العملاء",
  "mine-complaintEmail": "البريد الإلكتروني للشكاوى",
  "mine-announcement": "الإعلانات",
  "mine-establishment": "الإعدادات",
  "mine-other": "أخرى",
  "mine-change_theme": "تغيير المظهر",
  "dialog-title": "تنبيه",
  "dialog-logout-conetnt": "هل أنت متأكد أنك تريد تسجيل الخروج؟",
  "announce-text":
    "بسبب السياسات، لا نقدم خدمات حالياً إلى كوريا الشمالية، إسرائيل، الصين، فانواتو وكوبا.",
  "announce-text-demo": "（للعرض التجريبي فقط）",
  "verify-text-upload": "يرجى تحميل صورة جواز السفر/الهوية من الجهتين",
  "verify-front": "الواجهة الأمامية",
  "verify-front-desc": "تحميل الواجهة الأمامية لجواز السفر/الهوية",
  "verify-back": "الواجهة الخلفية",
  "verify-back-desc": "تحميل الواجهة الخلفية لجواز السفر/الهوية",
  "verify-text-info": "يرجى إكمال معلوماتك الشخصية",
  "verify-name": "الاسم",
  "verify-enterName": "يرجى إدخال اسمك",
  "verify-passportId": "رقم جواز السفر/الهوية",
  "verify-enterPassportId": "يرجى إدخال رقم جواز السفر/الهوية",
  "verify-infoAuth": "تحت المراجعة",
  "wallet-bind-bank": "ربط بطاقة البنك",
  "wallet-bind-currency-address": "ربط عنوان العملة الرقمية",
  "bind-bank-name": "اسم البنك",
  "bind-bank-address": " عنوان البنك",
  "bind-bank-swift": "SWIFT",
  "bind-bank-payee": "المستفيد",
  "bind-bank-payee-account": "حساب المستفيد",
  "bind-currency": "العملة",
  "bind-wallet-address": "عنوان المحفظة",
  "deposit-digitai-currency": "العملة الرقمية",
  "deposit-bank-card": "بطاقة البنك",
  "deposit-select_network": "اختر الشبكة",
  "deposit-coin-number": "كمية الإيداع",
  "deposit-coin-placeholder": "يرجى إدخال كمية الإيداع",
  "doposit-upload-cert": "تحميل الشهادة",
  "deposit-record": "سجل الإيداع",
  "deposit-recharge_amount": "مبلغ الإيداع",
  "deposit-recharge-placeholder": "يرجى إدخال مبلغ الإيداع",
  "deposit-recharge_type": "النوع",
  "deposit-recharge_unit": "الوحدة",
  "deposit-recharge_status": "الحالة",
  "deposit-receipt_currency": "عملة الاستلام",
  "withdraw-coin_address": "عنوان السحب",
  "withdraw-coin_amount": "الكمية",
  "withdraw-handling_fee": "الرسوم",
  "withdraw-expected": "الوقت المتوقع للوصول",
  "withdraw-btn-submit": "تقديم طلب السحب",
  "withdraw-coin_withdrawal_record": "سجل السحب",
  "withdraw-num_of_receipt": "عدد الاستلام",
  "withdraw-select_addess": "يرجى اختيار عنوان السحب",
  "order-position_holding": "المراكز المفتوحة",
  "order-pending_orders": "الأوامر المعلقة",
  "order-history": "التاريخ",
  "order-profit_loss": "إجمالي الأرباح/الخسائر",
  "order-current_margin": "الهامش الحالي",
  "order-risk_rate": "نسبة المخاطرة",
  "order-order_id": "معرف الطلب",
  "order-total_profit_loss": "إجمالي الأرباح/الخسائر",
  "order-order_details": "تفاصيل الطلب",
  "order-margin": "الهامش",
  "order-take_profit": "تحقيق الأرباح",
  "order-set_loss": "وقف الخسارة",
  "order-set_TPSL": "تعديل TP/SL",
  "order-close_position": "إغلاق المركز",
  "order-cancel_order": "إلغاء الطلب",
  "order-select_period": "اختر الفترة",
  "order-btn_rise": "صعود",
  "order-btn_fall": "هبوط",
  "order-estimated_return": "العائد المتوقع",
  "order-purchase_amount": "مبلغ الشراء",
  "search-search_product": "ابحث عن المنتج",
  "search-search_result": "نتائج البحث",
  "trade-market_price": "سعر السوق",
  "trade-price": "السعر",
  "trade-quick_trade": "الصفقة الفورية",
  "trade-please_enter_price": "يرجى إدخال السعر",
  "trade-each_lots": "لكل عقد",
  "trade-estimated_handling_fee": "الرسوم المقدرة",
  "trade-estimated_margin": "الهامش المقدر",
  "trade-lots_text": "عدد العقود (الخطوة: 0.001)",
  "trade-order_page": "صفحة الطلب",
  "trade-your_order_has_been_confirmed": "تم تأكيد طلبك",
  "trade-multiplier": "الضرب",
  "accHistory-financial_records": "السجلات المالية",
  "accHistory-records": "السجلات",

  Res_Msg1: "يرجى إدخال البريد الإلكتروني",
  Res_Msg2: "صيغة البريد الإلكتروني غير صحيحة",
  Res_Msg3: "يرجى إدخال كلمة المرور",
  Res_Msg4: "كلمة المرور وتكرارها غير متطابقين",
  Res_Msg5: "يجب أن لا تقل كلمة المرور عن 6 أحرف",
  Res_Msg6: "خطأ في إدخال رمز التحقق",
  Res_Msg7: "يرجى إدخال رمز التحقق",
  Res_su: "تم التسجيل بنجاح",
  Login_su: "تم تسجيل الدخول بنجاح",
  Loading: "لا يوجد المزيد",
  SDealCont_TopName: "التسليم",
  SDealCont_L1: "سعر الشراء",
  SDealCont_L2: "السعر الحالي",
  SDealCont_L3: "الاتجاه",
  SDealCont_L4: "الكمية",
  SDealCont_L5: "وقت التسليم",
  SDealCont_btn: "إغلاق",
  SDealCont_L6: "شراء طويل",
  SDealCont_L7: "بيع قصير",
  SDealCont_L8: "شراء",
  SDealCont_L9: "بيع",
  "verify-verified": "تم التحقق بنجاح",
  "verify-notVerified": "فشل التحقق",
  "加载中...": "جار التحميل...",
  "分时": "زمن",
  "登入密碼": "الرجاء إدخال كلمة المرور",
"登入密碼不能为空": "كلمة المرور لا يمكن أن تكون فارغة"
};
